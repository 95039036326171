// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appReports/showLoading",
  async (status) => {
    return status
  }
)

export const fetchUserDutyPaymentReport = createAsyncThunk(
  "appReports/fetchUserDutyPaymentReport",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${id.userID ? url.GET_ADMIN_DUTY_PAYMENTS_REPORT : url.GET_USER_DUTY_PAYMENTS_REPORT}?startDate=${id.startDate}&endDate=${id.endDate}${id.userID ? `&userID=${id.userID}` : ""}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchAdminDutyPaymentReport = createAsyncThunk(
  "appReports/fetchAdminDutyPaymentReport",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_ADMIN_DUTY_PAYMENTS_REPORT}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const appReportsSlice = createSlice({
  name: "appReports",
  initialState: {
    userReports: null,
    adminReports: null,
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDutyPaymentReport.fulfilled, (state, action) => {
        state.userReports = action.payload
      })
      .addCase(fetchAdminDutyPaymentReport.fulfilled, (state, action) => {
        state.adminReports = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export const { selectEvent } = appReportsSlice.actions

export default appReportsSlice.reducer 
