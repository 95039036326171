// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
import users from "@src/views/apps/user/store"
import systemOperations from "@src/views/systemOperations/store"
import forms from "@src/views/formBuilder/store"
import Workflow from "@src/views/workflow/store"
import calendar from "@src/views/calendar/store"
import GovInstitute from "@src/views/govInstitute/store"
import clients from "@src/views/clients/store"
import duties from "@src/views/duties/store"
import dashboard from "@src/views/dashboard/store"
import keyDates from "@src/views/keyDates/store"
import reports from '@src/views/reports/store'
import directions from '@src/views/directions/store'
import dateViews from '@src/views/dateViews/store'
import payCards from '@src/views/payCards/store'
import paymentRequests from '@src/views/paymentRequests/store'
const rootReducer = {
  auth,
  users,
  navbar,
  layout,
  systemOperations,
  forms,
  Workflow,
  calendar,
  GovInstitute,
  clients,
  duties,
  dashboard,
  keyDates,
  directions,
  reports,
  dateViews,
  payCards,
  paymentRequests
}

export default rootReducer
