// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from 'react-hot-toast'
import ToastContent from '../../ui-elements/toast'

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appKeyDates/showLoading",
  async (status) => {
    return status
  }
)

export const fetchEvents = createAsyncThunk(
  "appKeyDates/fetchEvents",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_KEY_DATES}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchClientDates = createAsyncThunk(
  "appKeyDates/fetchClientDates",
  id => {
    return id
  }
)

export const fetchInstitutetDates = createAsyncThunk(
  "appKeyDates/fetchInstitutetDates",
  id => {
    return id
  }
)

export const fetchHearningTypes = createAsyncThunk(
  "appDuties/fetchHearningTypes",
  async (val, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_HEARING_TYPES}?optionValueName=${val}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addEvent = createAsyncThunk(
  "appKeyDates/addEvent",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_NEW_KEY_DATE, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Date Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const updateEvent = createAsyncThunk(
  "appKeyDates/updateEvent",
  async (form, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_KEY_DATE, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Key Date Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)


export const deleteEvent = createAsyncThunk('appKeyDates/deleteEvent', async (form, { dispatch }) => {
  await useJwt.del(`${url.DELETE_KEY_DATE}`, form)
    .then(async () => {
      toast.success(() => (
        <ToastContent message="Key Date has been Deleted" />
      ), {
        position: "top-center",
        reverseOrder: false
      })
      await dispatch(fetchEvents())
      dispatch(showLoading(false))
    })
    .catch((res) => {
      dispatch(showLoading(false))
      toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
        position: "top-center",
        reverseOrder: false
      })

    })

  return form
})



export const addNewHearing = createAsyncThunk(
  "appKeyDates/addNewHearing",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_NEW_HEARING, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Key Date Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

// Perform Tasks 
export const performTask = createAsyncThunk(
  "appDuties/performTask",
  async (form, { dispatch }) => {
    await useJwt
      .post(`${url.PAYCARD_DO_STATE_TASK}?task_id=${form.ID}`, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Task Performed Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const updateFilter = createAsyncThunk(
  "appKeyDates/updateFilter",
  (filter) => { return filter }
)

export const updateAllFilters = createAsyncThunk(
  "appKeyDates/updateAllFilters",
  (value) => { return value }
)

export const appkeyDatesSlice = createSlice({
  name: "appKayDates",
  initialState: {
    selectedCalendars: [],
    events: [],
    filteredEvents: [],
    filteredTypeEvents: [],
    hearingTypes: [],
    isLoading: false,
    selectedEvent: {},
    eventTypes: [],
    selectedClient: null,
    selectedInstitute: null
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const newEvents = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newEvents.push({
            _id: item._id,
            title: item.client !== null ? `${item.client.FirstName} ${item.client.LastName}` : item.hearingDateTime,
            start: item.hearingDateTime,
            end: item.hearingDateTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              calendar: item.hearingType,
              GovernmentInsitute: item.institute,
              client: item.client !== null ? item.client._id : null,
              form: item.form ? item.form : null,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          })
          )
        }
        state.events = newEvents

        // const NewArr = []
        // newEvents.map((item) => {
        //   state.selectedCalendars.map((cal) => {
        //     if (item.extendedProps.calendar === cal) {
        //       NewArr.push(item)
        //     }
        //   })
        // })
        state.filteredEvents = newEvents

      })

      .addCase(fetchHearningTypes.fulfilled, (state, action) => {
        state.hearingTypes = action.payload.hearingTypes.value ? action.payload.hearingTypes.value : []
        if (state.selectedCalendars.length > 0) {
          state.selectedCalendars = state.selectedCalendars
        } else {
          const Types = []
          if (action.payload.hearingTypes.value) {
            action.payload.hearingTypes.value.map((val) => Types.push(val._id))
          }
          state.selectedCalendars = Types
        }
      })
      .addCase(fetchClientDates.fulfilled, (state, action) => {
        state.selectedClient = action.payload
        const NewArr = []
        state.events.map((item) => {
          state.selectedCalendars.map((cal) => {
            if (item.extendedProps.calendar === cal) {
              NewArr.push(item)
            }
          })
        })
        if (action.payload !== null) {
          const UpdatedArr = NewArr.filter((event) => event.extendedProps.client === action.payload.value)
          if (state.selectedInstitute !== null) {
            state.filteredEvents = UpdatedArr.filter((event) => event.extendedProps.GovernmentInsitute._id === state.selectedInstitute.value)
          } else {
            state.filteredEvents = UpdatedArr
          }
        } else {
          if (state.selectedInstitute !== null) {
            state.filteredEvents = NewArr.filter((event) => event.extendedProps.GovernmentInsitute._id === state.selectedInstitute.value)
          } else {
            state.filteredEvents = NewArr
          }
        }
      })
      .addCase(fetchInstitutetDates.fulfilled, (state, action) => {
        state.selectedInstitute = action.payload
        const NewArr = []
        state.events.map((item) => {
          state.selectedCalendars.map((cal) => {
            if (item.extendedProps.calendar === cal) {
              NewArr.push(item)
            }
          })
        })
        if (action.payload !== null) {
          const UpdatedArr = NewArr.filter((event) => event.extendedProps.GovernmentInsitute._id === action.payload.value)
          if (state.selectedClient !== null) {
            state.filteredEvents = UpdatedArr.filter((event) => event.extendedProps.client === state.selectedClient.value)
          } else {
            state.filteredEvents = UpdatedArr
          }
        } else {
          if (state.selectedClient !== null) {
            state.filteredEvents = NewArr.filter((event) => event.extendedProps.client === state.selectedClient.value)
          } else {
            state.filteredEvents = NewArr
          }
        }
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          const newCalType = state.selectedCalendars.filter((item) => item !== action.payload)
          state.selectedCalendars = newCalType
          const newArray = []
          newCalType.map((item) => {
            newArray.push(...state.events.filter((event) => event.extendedProps.calendar === item))
          })
          let NewFilteredData = []
          if (state.selectedInstitute !== null) {
            NewFilteredData = newArray.filter((event) => event.extendedProps.GovernmentInsitute._id === state.selectedInstitute.value)
          } else {
            NewFilteredData = newArray
          }

          if (state.selectedClient !== null) {
            NewFilteredData = NewFilteredData.filter((event) => event.extendedProps.client === state.selectedClient.value)
          } else {
            NewFilteredData = newArray
          }

          state.filteredEvents = NewFilteredData

        } else {
          const newCalType = [...state.selectedCalendars, action.payload]
          state.selectedCalendars = newCalType
          const newArray = []
          newCalType.map((item) => {
            newArray.push(...state.events.filter((event) => event.extendedProps.calendar === item))
          })
          let NewFilteredData = []
          if (state.selectedInstitute !== null) {
            NewFilteredData = newArray.filter((event) => event.extendedProps.GovernmentInsitute._id === state.selectedInstitute.value)
          } else {
            NewFilteredData = newArray
          }

          if (state.selectedClient !== null) {
            NewFilteredData = NewFilteredData.filter((event) => event.extendedProps.client === state.selectedClient.value)
          } else {
            NewFilteredData = newArray
          }

          state.filteredEvents = NewFilteredData
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        if (action.payload === true) {
          const selected = []
          state.hearingTypes.map((val) => selected.push(val._id)
          )
          state.selectedCalendars = selected
          let NewFilteredData = []
          if (state.selectedInstitute !== null) {
            NewFilteredData = state.events.filter((event) => event.extendedProps.GovernmentInsitute._id === state.selectedInstitute.value)
          } else {
            NewFilteredData = state.events
          }

          if (state.selectedClient !== null) {
            NewFilteredData = NewFilteredData.filter((event) => event.extendedProps.client === state.selectedClient.value)
          } else {
            NewFilteredData = NewFilteredData
          }

          state.filteredEvents = NewFilteredData
        } else {
          state.selectedCalendars = []
          state.filteredEvents = []
        }

      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export const { selectEvent } = appkeyDatesSlice.actions

export default appkeyDatesSlice.reducer 
