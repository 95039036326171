import { Ability, AbilityBuilder } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// const userData = JSON.parse(localStorage.getItem('userData'))
// const existingAbility = userData ? userData.ability : null

const newPerm = ["view_dashboard"]

const Permissions = JSON.parse(localStorage.getItem('Permissions'))

if (Permissions) {
Permissions.map((item) => {
  if (item.permission !== null) {
        const newDa = `${item.permission.permissionName}_${item.permission.module.length > 0 ? item.permission.module[0].moduleName : 'noModule' }`
        newPerm.push(newDa)
  }
    })
  }
    const { can, rules } = new AbilityBuilder()

    if (newPerm.length > 0) {
      newPerm.forEach((p) => {
      const per = p.split("_")
      can(per[0], per[1])
    })
  }

export default new Ability(rules || initialAbility)
