// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import * as url from '../../../helpers/url_helper'
import toast from 'react-hot-toast'
import ToastContent from '../../ui-elements/toast'

// ** for Loading Spinner
export const showLoading = createAsyncThunk('appClients/showLoading', async status => {
  return status
})

export const getAllData = createAsyncThunk('appClients/getAllData', async (id, { dispatch }) => {

  const response = await useJwt.get(`${url.GET_ALL_CLEINTS}`)
  if (response) {
    dispatch(showLoading(false))
  }
  return response.payload
})

export const getEthnicityOptions = createAsyncThunk('appClients/getEthnicityOptions', async () => {
  const response = await useJwt.get(`${url.GET_ETHNICITY_OPTIONS}`)
  return response.payload
})

export const addClient = createAsyncThunk(
  "appClients/addClient",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_CLIENT, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Client added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const addClientAddress = createAsyncThunk(
  "appClients/addClientAddress",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_CLIENT_ADDRESS, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Address added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const editClientAddress = createAsyncThunk(
  "appClients/editClientAddress",
  async (form, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_CLIENT_ADDRESS, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Address Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const addElectronicAddress = createAsyncThunk(
  "appClients/addElectronicAddress",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_ELECTRONIC_ADDRESS, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Address added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const editElectronicAddress = createAsyncThunk(
  "appClients/editElectronicAddress",
  async (form, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_ELECTRONIC_ADDRESS, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Address Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const editClientInfo = createAsyncThunk(
  "appClients/editClientInfo",
  async (form, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_CLIENT_INFO, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Info Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const appClientsSlice = createSlice({
  name: 'appClients',
  initialState: {
    allData: [],
    ethnicityOptions: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
      .addCase(getEthnicityOptions.fulfilled, (state, action) => {
        state.ethnicityOptions = action.payload
      })
  }
})

export default appClientsSlice.reducer
