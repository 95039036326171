// import { Fragment } from "react"
// import { toast, Slide } from "react-toastify"
import Avatar from "@components/avatar"
import {
  Check
  // X
} from "react-feather"

const ToastContent = (props) => {
  return (
    <div className='d-flex'>
      {/* <div className='me-1'>
        <Avatar size='sm' color='success' icon={<Check size={12} />} />
      </div> */}
      <div className='d-flex flex-column'>
        <span>{props.message}</span>
      </div>
    </div>
  )
  
}
export default ToastContent
