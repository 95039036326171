// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from 'react-hot-toast'
import ToastContent from '../../ui-elements/toast'
// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appPaymentRequests/showLoading",
  async (status) => {
    return status
  }
)

export const getUserPaymentRequests = createAsyncThunk(
  "appPaymentRequests/getUserPaymentRequests",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_USER_PAYMENT_REQUEST}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getAdminPaymentRequests = createAsyncThunk(
  "appPaymentRequests/getAdminPaymentRequests",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_ADMIN_PAYMENT_REQUEST}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const createPaymentDraft = createAsyncThunk(
  "appPaymentRequests/createPaymentDraft",
  async (form, { dispatch }) => {
    const response = await useJwt
      .post(url.CREATE_DRAFT_PAYMENT_REQUEST, form)
      .then(async (res) => {
        toast.success(
          () => <ToastContent message="Draft Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        dispatch(getUserPaymentRequests())
        return res.payload
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return response
  }
)

export const createPaymentRequest = createAsyncThunk(
  "appPaymentRequests/createPaymentRequest",
  async (form, { dispatch }) => {
    const response = await useJwt
      .post(url.CREATE_DOCUMENT_PAYMENT_REQUEST, form)
      .then(async (res) => {
        toast.success(
          () => <ToastContent message="Payment request Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        dispatch(getUserPaymentRequests())
        return res.payload
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return response
  }
)

export const getPaymentRequestsDetails = createAsyncThunk(
  "appPaymentRequests/getPaymentRequestsDetails",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_PAYMENT_REQUEST_DETAIL}${id}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const addComment = createAsyncThunk(
  "appPaymentRequests/addComment",
  async (form, { dispatch }) => {
    const response = await useJwt
      .put(url.ADD_COMMENT_TO_PAYMENT_REQUEST, form)
      .then(async (res) => {
        toast.success(
          () => <ToastContent message="Comment Added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        dispatch(getUserPaymentRequests())
        return res.payload
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return response
  }
)

export const downloadOwnDocuments = createAsyncThunk(
  "appForms/getPic",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.DOWNLAOD_OWN_PAYMENT_REQUEST_DOCUMENTS}${id._id}`, { responseType: 'blob' })
      .then(async (res) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([res], { type: id.mimeType })
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file)
        //Open the URL on new Window
        window.open(fileURL)
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response
  }
)

export const getAdminPaymentRequestsDetails = createAsyncThunk(
  "appPaymentRequests/getAdminPaymentRequestsDetails",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_ADMIN_PAYMENT_REQUEST_DETAIL}${id}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const approveRequest = createAsyncThunk(
  "appPaymentRequests/approveRequest",
  async (form, { dispatch }) => {
    const response = await useJwt
      .put(url.APPROVE_PAYMENT_REQUEST, form)
      .then(async (res) => {
        toast.success(
          () => <ToastContent message="Request Approved Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        dispatch(getAdminPaymentRequests())
        return res.payload
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return response
  }
)

export const rejectRequest = createAsyncThunk(
  "appPaymentRequests/rejectRequest",
  async (form, { dispatch }) => {
    const response = await useJwt
      .put(url.REJECT_PAYMENT_REQUEST, form)
      .then(async (res) => {
        toast.success(
          () => <ToastContent message="Request Reject Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        dispatch(getAdminPaymentRequests())
        return res.payload
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })
    return response
  }
)



export const appPaymentRequestsSlice = createSlice({
  name: "appPaymentRequests",
  initialState: {
    userPaymentRequests: [],
    paymentRequests: [],
    paymentRequestDetails: null,
    paymentRequestDetailsAdmin: null,
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPaymentRequests.fulfilled, (state, action) => {
        state.userPaymentRequests = action.payload
      })
      .addCase(getAdminPaymentRequests.fulfilled, (state, action) => {
        state.paymentRequests = action.payload
      })
      .addCase(getPaymentRequestsDetails.fulfilled, (state, action) => {
        state.paymentRequestDetails = action.payload
      })
      .addCase(getAdminPaymentRequestsDetails.fulfilled, (state, action) => {
        state.paymentRequestDetailsAdmin = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export const { selectEvent } = appPaymentRequestsSlice.actions

export default appPaymentRequestsSlice.reducer 
