// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from 'react-hot-toast'
import ToastContent from '../../ui-elements/toast'

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appdateViews/showLoading",
  async (status) => {
    return status
  }
)

export const fetchKeyDates = createAsyncThunk(
  "appdateViews/fetchKeyDates",
  async () => {
    const response = await useJwt.get(`${url.GET_KEY_DATES}`)
      .then(async (res) => {
        // dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        // dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchDirections = createAsyncThunk(
  "appdateViews/fetchDirections",
  async () => {
    const response = await useJwt.get(`${url.GET_DIRECTIONS}`)
      .then(async (res) => {
        // dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        // dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchOwnDuties = createAsyncThunk(
  "appdateViews/fetchOwnDuties",
  async () => {
    const response = await useJwt.get(`${url.GET_OWN_DUTIES}`)
      .then(async (res) => {
        // dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        // dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchUserDuties = createAsyncThunk(
  "appDuties/fetchUserDuties",
  async (id) => {
    const response = await useJwt.get(`${url.GET_USER_DUTIES}?userID=${id}`)
      .then(async (res) => {
        // dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        // dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const fetchDutyTypes = createAsyncThunk(
  "appdateViews/fetchDutyTypes",
  async () => {
    const response = await useJwt.get(`${url.GET_DUTY_TYPES}`)
    // if (response) {
    //   dispatch(showLoading(false))
    // }
    return response.payload
  }
)

export const fetchHearningTypes = createAsyncThunk(
  "appdateViews/fetchHearningTypes",
  async (val, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_HEARING_TYPES}?optionValueName=${val}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const addKeyDate = createAsyncThunk(
  "appdateViews/addKeyDate",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_NEW_KEY_DATE, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Hearing Date Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const addDirection = createAsyncThunk(
  "appdateViews/addDirection",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_NEW_DIRECTION, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Direction Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const addDuty = createAsyncThunk(
  "appdateViews/addDuty",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.CREATE_OWN_DUTY, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Duty Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const fetchAllDuties = createAsyncThunk(
  "appdateViews/fetchAllDuties",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_ALL_DUTIES}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const appdateViewsSlice = createSlice({
  name: "appdateViews",
  initialState: {
    selectedCalendars: [],
    keyDates: [],
    duties: [],
    directions: [],
    dutyTypes: [],
    hearingTypes: [],
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeyDates.fulfilled, (state, action) => {
        const newEvents = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newEvents.push({
            _id: item._id,
            title: `${item.label}`,
            start: item.directionDateTime,
            end: item.directionDateTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              calendar: item.hearingType,
              GovernmentInsitute: item.institute,
              client: item.client !== null ? item.client._id : null,
              form: item.form ? item.form : null,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          }))
        }
        state.keyDates = newEvents
      })

      .addCase(fetchOwnDuties.fulfilled, (state, action) => {
        const newEvents = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newEvents.push({
            id: item._id,
            title: item.GovernmentInsitute !== null ? item.GovernmentInsitute.GovInstitutionInfo.Name : item.PsScheme && item.PsScheme !== null ? item.PsScheme.SchemeName : '',
            start: item.startTime,
            end: item.endTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              calendar: item.dutyType,
              isArchived: item.isArchived,
              GovernmentInsitute: item.GovernmentInsitute,
              PsScheme: item.PsScheme && item.PsScheme !== null ? item.PsScheme : null,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          })
          )
        }
        state.duties = newEvents
      })

      .addCase(fetchDirections.fulfilled, (state, action) => {
        const newEvents = []
        if (Object.keys(action.payload).length !== 0) {
          action.payload.map((item) => newEvents.push({
            _id: item._id,
            title: `${item.label}`,
            start: item.directionDateTime,
            end: item.directionDateTime,
            textColor: item.meta ? item.meta.color ? '#ffffff' : '' : '',
            borderColor: item.meta ? item.meta.color ? item.meta.color : '#4b4b4b' : '#4b4b4b',
            color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            display: 'block',
            extendedProps: {
              client: item.client !== null ? item.client._id : null,
              form: item.form ? item.form : null,
              color: item.meta ? item.meta.color ? item.meta.color : 'transparent' : 'transparent',
            }
          }))
        }
        state.directions = newEvents
      })
      .addCase(fetchDutyTypes.fulfilled, (state, action) => {
        state.dutyTypes = action.payload
      })
      .addCase(fetchHearningTypes.fulfilled, (state, action) => {
        state.hearingTypes = action.payload.hearingTypes.value ? action.payload.hearingTypes.value : []
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export const { selectEvent } = appdateViewsSlice.actions

export default appdateViewsSlice.reducer 
