// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from 'react-hot-toast'
import ToastContent from '../../ui-elements/toast'
// ** Axios Imports
import axios from "axios"

// ** for Loading Spinner
export const showLoading = createAsyncThunk(
  "appForms/showLoading",
  async (status) => {
    return status
  }
)

export const fetchEvents = createAsyncThunk(
  "appForms/fetchEvents",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_CALENDER_EVENT}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const fetchCalenderTypes = createAsyncThunk(
  "appForms/fetchCalenderTypes",
  async (id, { dispatch }) => {
    const response = await useJwt.get(`${url.GET_CALENDER_TYPES}`)
    if (response) {
      dispatch(showLoading(false))
    }
    return response.payload
  }
)

export const fetchEventTypes = createAsyncThunk(
  "appForms/fetchEventTypes",
  async () => {
    const response = await useJwt.get(`${url.GET_EVENT_TYPES}`)
    return response.payload
  }
)

export const fetchLocationTypes = createAsyncThunk(
  "appForms/fetchLocationTypes",
  async () => {
    const response = await useJwt.get(`${url.GET_LOCATION_TYPES}`)
    return response.payload
  }
)

// export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async calendars => {
//   const response = await axios.get('/apps/calendar/events', { calendars })
//   return response.data
// })
export const addEvent = createAsyncThunk(
  "appCalendar/addEvent",
  async (form, { dispatch }) => {

    await useJwt
      .post(url.CREATE_CALENDER_EVENT, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Event Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(fetchEvents())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

// export const addEvent = createAsyncThunk(
//   "appCalendar/addEvent",
//   async (event, { dispatch, getState }) => {
//     await axios.post("/apps/calendar/add-event", { event }) 
//     await dispatch(fetchEvents(getState().calendar.selectedCalendars)) 
//     return event 
//   }
// ) 

export const updateEvent = createAsyncThunk(
  "appCalendar/updateEvent",
  async (event, { dispatch, getState }) => {
    await axios.post("/apps/calendar/update-event", { event })
    await dispatch(fetchEvents(getState().calendar.selectedCalendars))
    return event
  }
)

export const updateFilter = createAsyncThunk(
  "appCalendar/updateFilter",
  (filter) => {
    return filter
  }
)

export const updateAllFilters = createAsyncThunk(
  "appCalendar/updateAllFilters",
  (value) => {
    return value
  }
)

export const removeEvent = createAsyncThunk(
  "appCalendar/removeEvent",
  async (id) => {
    await axios.delete("/apps/calendar/remove-event", { id })
    return id
  }
)

export const appCalendarSlice = createSlice({
  name: "appCalendar",
  initialState: {
    events: [],
    filteredEvents: [],
    calenderTypes: null,
    isLoading: false,
    selectedEvent: {},
    selectedCalendars: [],
    eventTypes: [],
    locationTypes: []
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const newEvents = []
        action.payload.duties.map((item) => newEvents.push({
          id: item._id,
          title: item.title,
          start: item.startDate,
          end: item.endDate,
          allDay: item.allDay,
          extendedProps: {
            calendar: item.calenderType,
            locationType: item.locationType,
            location: item.location,
            description: item.description,
            isActive: item.isActive,
            groups: item.groups
          }
        })
        )
        state.events = newEvents
        state.filteredEvents = newEvents
      })
      .addCase(fetchCalenderTypes.fulfilled, (state, action) => {
        state.calenderTypes = action.payload
        if (state.selectedCalendars.length > 0) {
          state.selectedCalendars = state.selectedCalendars
        } else {
          const Types = []
          action.payload.options.map((item) => item.optionValues.map((val) => Types.push(val._id))
            // Types.push(item)
          )
          state.selectedCalendars = Types
        }
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          const newArray = state.selectedCalendars.splice(
            state.selectedCalendars.indexOf(action.payload),
            1
          )
          state.filteredEvents = state.events.filter(
            (event) => event.extendedProps.calendar !== newArray[0]
          )
        } else {
          state.selectedCalendars.push(action.payload)
          state.filteredEvents = [
            ...state.filteredEvents,
            ...state.events.filter(
              (event) => event.extendedProps.calendar === action.payload
            )
          ]
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          state.calenderTypes.options.map((item) => item.optionValues.map((val) => selected.push(val._id))
          )
          selected = selected
          state.filteredEvents = state.events
        } else {
          selected = []
          state.filteredEvents = []
        }
        state.selectedCalendars = selected
      })
      .addCase(fetchEventTypes.fulfilled, (state, action) => {

        const Types = []
        action.payload.options.map((item) => (
          item.optionValues.map((val) => (
            Types.push({
              label: val.label,
              value: val._id
            })
          )
          )
        )
        )
        state.eventTypes = Types
      })
      .addCase(fetchLocationTypes.fulfilled, (state, action) => {
        const Types = []
        action.payload.options.map((item) => (
          item.optionValues.map((val) => (
            Types.push({
              label: val.label,
              value: val._id
            })
          )
          )
        )
        )
        state.locationTypes = Types
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer 
