// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import * as url from "../../../helpers/url_helper"
import toast from "react-hot-toast"
import ToastContent from "../../ui-elements/toast"

export const showLoading = createAsyncThunk(
  "appForms/showLoading",
  async (status) => {
    return status
  }
)

export const getAllData = createAsyncThunk(
  "appForms/getAllData",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(url.GET_FORM_TYPES)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getForm = createAsyncThunk(
  "appForms/getForm",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_SINGLE_FORM_TYPE}${id !== undefined ? id : ""}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getPic = createAsyncThunk(
  "appForms/getPic",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.VIEW_DOCUMENT}?id=${id.documentID}`, { responseType: 'blob' })
      .then(async (res) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([res], { type: id.mime })
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file)
        //Open the URL on new Window
        window.open(fileURL)
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response
  }
)

export const downloadFile = createAsyncThunk(
  "appForms/downloadFile",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.VIEW_DOCUMENT}?id=${id.documentID}`, { responseType: 'blob' })
      .then(async (res) => {
        dispatch(showLoading(false))
        const url = window.URL.createObjectURL(res)
        const a = document.createElement('a')
        a.href = url
        a.download = id.friendlyName
        document.body.appendChild(a) // append the element to the dom
        a.click()
        a.remove()
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response
  }
)

export const addForm = createAsyncThunk(
  "appForms/addForm",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.CREATE_FORM_TYPE, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Form Type Created Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const updateForm = createAsyncThunk(
  "appForms/updateForm",
  async (form, { dispatch }) => {
    await useJwt
      .put(url.UPDATE_FORM_VERSION, form)
      .then(async () => {
        toast.success(
          () => <ToastContent message="Form Updated Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getAllData())
        await dispatch(getForm(form.formTypeID))
        dispatch(showLoading(false))
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const deleteForm = createAsyncThunk(
  "appForm/deleteForm",
  async (id, { dispatch }) => {
    await useJwt.delete(url.DELETE_FORM_TYPE, { id })
    await dispatch(getAllData())
    dispatch(showLoading(false))
    return id
  }
)

export const deleteFormSubmission = createAsyncThunk(
  "appForm/deleteFormSubmission",
  async (data, { dispatch }) => {
    await useJwt.del(url.DELETE_FORM, { "data": { 'formID': data.id } }).then(async (res) => {
      toast.success(
        () => <ToastContent message={`Form Deleted Successfully`} />,
        {
          position: "top-center",
          reverseOrder: false
        }
      )
      await dispatch(getUserFormList(data.id2))
      return res
    })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    dispatch(showLoading(false))
    return id
  }
)

export const submitForm = createAsyncThunk(
  "appForms/submitForm",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.SUBMIT_FORM, form)
      .then(async () => {
        dispatch(showLoading(false))
        toast.success(
          () => <ToastContent message="Form submitted Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const doTransition = createAsyncThunk(
  "appForms/doTransition",
  async (form, { dispatch }) => {
    const newArray = []
    form.submission.formSubmission.map((item) => item.sectionFields.map(
      (ch) => ch.fieldType === "File" &&
        newArray.push({
          documentName: ch.fileName,
          value: ch.fieldValue,
          file: ch.file
        })
    )
    )
    // const ReqID = form.requestedTransitionID && `&requestedTransitionID=${from.requestedTransitionID}`
    const response = await useJwt
      .post(
        `${url.DO_TRANSITION}?workFlowItemType=FORM&${form.isNewForm !== undefined ? `isNew=true` : ""
        }${form.requestedTransitionID !== undefined ? `&requestedTransitionID=${form.requestedTransitionID}` : ""
        }`,
        form
      )
      .then((res) => {
        toast.success(
          () => <ToastContent message={`${form.isSaved ? 'Form submitted Successfully' : 'Form Saved as Draft'}`} />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )
        dispatch(showLoading(false))
        return res
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return response
  }
)

export const getSubmissionsByType = createAsyncThunk(
  "appForms/getSubmissionsByType",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_FORM_SUBMISSION_BY_TYPE}${id !== undefined ? id : ""}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getUserFormTypes = createAsyncThunk(
  "appForms/getUserFormTypes",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(url.GET_USER_FORM_TYPES)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getAuthFormTypes = createAsyncThunk(
  "appForms/getAuthFormTypes",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(url.GET_AUTH_FORM_TYPES)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getUserFormList = createAsyncThunk(
  "appForms/getUserFormList",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_USER_FORM_LIST_VIEW}${id !== undefined ? id : ""}`)
      .then(async (res) => {

        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response
  }
)

export const getAuthFormList = createAsyncThunk(
  "appForms/getAuthFormList",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_AUTH_FORM_LIST_VIEW}${id !== undefined ? id : ""}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getUserFormSubmission = createAsyncThunk(
  "appForms/getUserFormSubmission",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_USER_SUBMISSION_FORM_BY_ID}${id !== undefined ? id : ""}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getAuthFormSubmission = createAsyncThunk(
  "appForms/getAuthFormSubmission",
  async (id, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_AUTH_SUBMISSION_FORM_BY_ID}${id !== undefined ? id : ""}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const addComment = createAsyncThunk(
  "appForms/addComment",
  async (form, { dispatch }) => {
    await useJwt
      .post(url.ADD_COMMENT_TO_FROM, form)
      .then(async () => {
        dispatch(showLoading(false))
        window.location.reload(false)
        toast.success(
          () => <ToastContent message="Comment added Successfully" />,
          {
            position: "top-center",
            reverseOrder: false
          }
        )

        await dispatch(getAllData())
      })
      .catch((res) => {
        dispatch(showLoading(false))
        toast.error(() => <ToastContent message={res.response.data.error.msg ? res.response.data.error.msg : res.response.data.error} />, {
          position: "top-center",
          reverseOrder: false
        })
      })

    return form
  }
)

export const getAvailablePrefixes = createAsyncThunk(
  "appForms/getAvailablePrefixes",
  async (prefix, { dispatch }) => {
    const response = await useJwt
      .get(`${url.CHECK_AVAILABLE_PREFIXES}?prefix=${prefix}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getHearingTypes = createAsyncThunk(
  "appForms/getHearingTypes",
  async (val, { dispatch }) => {

    const response = await useJwt.get(`${url.GET_HEARING_TYPES}?optionValueName=${val}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

// Delete form Document

export const deleteFormDocument = createAsyncThunk(
  "appForm/deleteFormDocument",
  async (data, { dispatch }) => {
    const response = await useJwt.del(url.DELETE_FORM_DOCUMENT, { data: data })
    // await dispatch(getUserFormSubmission(data.fromID))
    dispatch(showLoading(false))
    return response
  }
)

// export const fetchHearningTypes = createAsyncThunk(
//   "appDuties/fetchHearningTypes",
//   async (val, { dispatch }) => {
//     const response = await useJwt.get(`${url.GET_HEARING_TYPES}?optionValueName=${val}`)
//     if (response) {
//       dispatch(showLoading(false))
//     }
//     return response.payload
//   }
// )

export const getMatterTypes = createAsyncThunk(
  "appForms/getMatterTypes",
  async (prefix, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_Matter_TYPES}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getDisabilities = createAsyncThunk(
  "appForms/gitDisabilities",
  async (prefix, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_DISABILITIES}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)


export const getFormCategories = createAsyncThunk(
  "appForms/getFormCategories",
  async (prefix, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_FORM_CATEGORIES}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const getEthnicityOptions = createAsyncThunk(
  "appForms/getEthnicityOptions",
  async (prefix, { dispatch }) => {
    const response = await useJwt
      .get(`${url.GET_ETHNICITY_OPTIONS}`)
      .then(async (res) => {
        dispatch(showLoading(false))
        return res
      })
      .catch(() => {
        dispatch(showLoading(false))
      })
    return response.payload
  }
)

export const appFormsSlice = createSlice({
  name: "appForms",
  initialState: {
    allData: [],
    selectedForm: [],
    isLoading: false,
    formSubmissionsByType: [],
    userFormTypes: [],
    userForms: null,
    authFormTypes: [],
    authForms: null,
    pic: null,
    userFormSubmission: null,
    authFormSubmission: null,
    availablePrefixes: null,
    hearingTypes: [],
    matterTypes: [],
    disabilities: [],
    ethnicityOptions: [],
    downloadedFile: null,
    formCategories: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getForm.fulfilled, (state, action) => {
        state.selectedForm = action.payload
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
      .addCase(getSubmissionsByType.fulfilled, (state, action) => {
        state.formSubmissionsByType = action.payload
      })
      .addCase(getUserFormTypes.fulfilled, (state, action) => {
        state.userFormTypes = action.payload
      })
      .addCase(getAuthFormTypes.fulfilled, (state, action) => {
        state.authFormTypes = action.payload
      })
      .addCase(getUserFormList.fulfilled, (state, action) => {

        if (action.payload !== undefined) {
          state.userForms = action.payload.payload
        } else {
          state.userForms = null
        }
      })
      .addCase(getAuthFormList.fulfilled, (state, action) => {
        state.authForms = action.payload
      })
      .addCase(getUserFormSubmission.fulfilled, (state, action) => {
        state.userFormSubmission = action.payload
      })
      .addCase(getAuthFormSubmission.fulfilled, (state, action) => {
        state.authFormSubmission = action.payload
      })
      .addCase(getPic.fulfilled, (state, action) => {
        state.pic = action.payload
      })
      .addCase(getAvailablePrefixes.fulfilled, (state, action) => {
        state.availablePrefixes = action.payload.prefix_available
      })
      .addCase(getHearingTypes.fulfilled, (state, action) => {
        const newArr = []
        if (action.payload.hearingTypes.value) {
          action.payload.hearingTypes.value.map((item) => {
            newArr.push({ label: item.label, value: item.value })
          })
        }
        state.hearingTypes = newArr
      })
      .addCase(getMatterTypes.fulfilled, (state, action) => {
        state.matterTypes = action.payload
      })
      .addCase(getDisabilities.fulfilled, (state, action) => {
        state.disabilities = action.payload
      })
      .addCase(getEthnicityOptions.fulfilled, (state, action) => {
        state.ethnicityOptions = action.payload
      })
      .addCase(getFormCategories.fulfilled, (state, action) => {
        const Types = []
        action.payload.options.map((item) => (
          item.optionValues.map((val) => (
            Types.push({
              label: val.label,
              value: val._id
            })
          )
          ))
        )
        state.formCategories = Types
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.downloadedFile = action.payload
      })
  }
})

export default appFormsSlice.reducer 
